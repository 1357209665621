import Header from '../components/Header';
import imgRight from '../assets/registr-img.svg';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import { Formik, Form, useField } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import imgForBtnReg from '../assets/reg-btn.png';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import axios from "axios"

export default function Login() {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false)
	const [vertical, setVertical] = useState('top')
	const [horizontal, setHorizontal] = useState('center')
	const { t } = useTranslation();
	const handleClick = () => () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const action = (
		<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={handleClose}
		>
			<CloseIcon fontSize="small" />
		</IconButton>
	);

	const validationSchema = yup.object().shape({
		email: yup.string().email(t('InvalidEmail')).required(t('Required')),
		password: yup.string().min(4, t('MinLength')).required(t('Required')),
	});

	const initialValues = {
		email: '',
		password: '',
	};

	const onSubmit = (values, { setSubmitting }) => {
		// console.log(values);
		axios.post(
			"https://itstep-veral-test.kz/api/v1/authorization/",
			{
					"email": values.email,
					"password": values.password,
			},
		)
		.then(data => {
			// console.log(data);
			if (data.data.result) {
				setSubmitting(false);
				localStorage.setItem("token", data.data.jwt_token)
				axios.get("https://itstep-veral-test.kz/api/v1/checkAdmin/", {
					headers: {
						"token": localStorage.getItem("token")
					}
				})
					.then(response => {
						// console.log(response);
						if (response.data.is_admin) {
							navigate('/admin');
						} else {
							if (JSON.parse(localStorage.getItem("is_college"))) {
								navigate("/progress-college")
							} else {
								navigate('/progress')
							}
							localStorage.removeItem("is_college")
						}
					})
					.catch(error => {
						console.error("There was an error making the request:", error);
					});
			} else {
				// console.log("error");
				setOpen(true);
			}
		})

		
	};

	const MyTextInput = ({ label, ...props }) => {
		const [field, meta] = useField(props);
		return (
			<>
				<input className="text-input" {...field} {...props} />
				{meta.touched && meta.error ? (
					<div className="error" style={{ color: '#FFFFFF' }}>{meta.error}</div>
				) : null}
			</>
		);
	};

	return (
		<>
			<div className="wrapper-three">
				<div className="container">
					<Header></Header>
					<div className="main-block">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>

							{formik => (
								<>
									<Form className='form-for-reg'>
										<div className="main-block-left2" id='for-mainblockleft2'>
											<div className="form">
												<h2 className="main-block-left2-reg">{t('Authorization')}</h2>
												<label className="lbl-for-reg" htmlFor="inp2">{t('Email')}</label>
												<MyTextInput
													name="email"
													id="inp2"
													type="email"
												/>
												<label className="lbl-for-reg" htmlFor="inp4">{t('EnterPassword')}</label>
												<MyTextInput
													name="password"
													id="inp4"
													type="password"
												/>
											</div>
											<Button type='submit' className='btn-with-img' id='extForRegistr'><img src={imgForBtnReg} alt="no img" className='img-from-btn' /></Button>
										</div>
									</Form>
								</>)}
						</Formik>
						<div className="main-block-right main-block-right2">
							<NavLink to="/registration" className="btns btns3 btns4">{t('Registration')}</NavLink>
							<img src={imgRight} alt="no img" className='img-for-reg img-for-login' />
						</div>
					</div>
					<Snackbar
						anchorOrigin={{ vertical, horizontal }}
						open={open}
						onClose={handleClose}
						message={t('InvalidLoginOrPassword')}
						action={action}
						className='snackbar-red'
					/>
				</div>
			</div>
		</>
	)
}
