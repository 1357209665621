import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { Provider } from 'react-redux'
import ErrorPage from './pages/error-page';
import store from './app/store';
import Main from './pages/Main';
import Registration from './components/Registration';
import Introduction from './components/Introduction';
import IntroductionCollege from './components/IntroductionCollege';
import Progress from './components/Progress';
import Login from './components/Login';
import TypingTest from './components/TypingTest';
import Contacts from './components/Contacts';
import Question from './components/Question';
import TypingTestCheck from './components/TypingTestCheck';
import Admin from './components/Admin';
import './components/i18n.js';
import ProgressCollege from './components/ProgressCollege';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/main",
    element: <Main />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/contacts",
    element: <Contacts />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/registration",
    element: <Registration />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/login",
    element: <Login />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/introduction",
    element: <Introduction />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/introduction-college",
    element: <IntroductionCollege />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/progress",
    element: <Progress />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/progress-college",
    element: <ProgressCollege />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/tests/typing",
    element: <TypingTest />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/tests/typing/write",
    element: <TypingTestCheck />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/tests/:testType",
    element: <Question />,
		errorElement: <ErrorPage />,
  },
	{
    path: "/admin",
    element: <Admin />,
		errorElement: <ErrorPage />,
  },
	// {
  //   path: "/tests/knowledge",
  //   element: <Question />,
	// 	errorElement: <ErrorPage />,
  // },
	// {
  //   path: "contacts/:contactId",
  //   element: <App />,
  // },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
	<Provider store={store}>
		<RouterProvider router={router}/>
	</Provider>
);

reportWebVitals();
