import Header from './Header';
import typeImg from '../assets/typing.png';
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setTestInfo } from './modalSlice';

export default function TypingTestCheck() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const testInfo = useSelector((state) => state.modal.testInfo);

	const initialParagraph = "И я узрел: отверста дверь на небе. И прежний глас, который слышал я. Как звук трубы, гремевшей надо мною. Мне повелел: войди и зри, что будет. И дух меня мгновенно осенил. И се - на небесах перед очами. Стоял престол, на нем же был сидящий. И сей сидящий, славою сияя."
	const [paragraphs] = useState(initialParagraph.split(""));
	const [charIndex, setCharIndex] = useState(0);
	const [errors, setErrors] = useState(0);
	const [timeLeft, setTimeLeft] = useState(60);
	const [isTyping, setIsTyping] = useState(false);
	const [typedChar, setTypedChar] = useState([])
	const [userInputStatus, setUserInputStatus] = useState([]);
	const [userInput, setUserInput] = useState([]);
	const [typingStarted, setTypingStarted] = useState(false);

	const inputRef = useRef(null);
	const timerRef = useRef(null);

	const handleKeyDown = (event) => {
		inputRef.current.focus();
	};

	useEffect(() => {
		if (charIndex === paragraphs.length - 2) {
			clearInterval(timerRef.current);
		}
	}, [charIndex]);

	function initTyping(event) {
		if (timeLeft === 0 || charIndex >= paragraphs.length) {
			clearInterval(timerRef.current);
			setIsTyping(false);
			event.target.value = "";
			setTypingStarted(true)
			return;
		}

		const currentInput = event.target.value.split("");
		setUserInput(currentInput);
		const currentChar = currentInput[charIndex];

		if (!isTyping) {
			timerRef.current = setInterval(() => {
				setTimeLeft(prevTime => {
					if (prevTime > 0) {
						return prevTime - 1;
					} else {
						clearInterval(timerRef.current);
						setTypingStarted(true)
						setIsTyping(false);
						return 0;
					}
				});
			}, 1000);
			setIsTyping(true);
		}

		if (currentChar === undefined) {
			if (charIndex > 0) {
				setCharIndex(charIndex - 1);
				if (userInputStatus[charIndex - 1] === 'incorrect') {
					setErrors(errors - 1);
				}
				setUserInputStatus(userInputStatus.slice(0, -1));
			}
		} else { 
			if (charIndex < paragraphs.length) {
				setTypedChar(currentChar);
				setCharIndex(charIndex + 1);

				if (currentChar !== paragraphs[charIndex]) {
					setErrors(errors + 1);
					setUserInputStatus([...userInputStatus, 'incorrect']);
				} else {
					setUserInputStatus([...userInputStatus, 'correct']);
				}
			}
		}
	}

	const handleFinish = () => {
		const resultPercentage = Math.ceil(((charIndex - errors) / paragraphs.length) * 100);
		axios.post('https://itstep-veral-test.kz/api/v1/updateTestResult/', {
			"link": "typing",
			"result": resultPercentage,
		}, {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				// console.log('POST запрос выполнен успешно', response.data);
				axios.get("https://itstep-veral-test.kz/api/v1/getUserResults/", {
					headers: {
						"token": localStorage.getItem("token")
					}
				})
					.then(response => {
						if (response.data.test_results) {
							dispatch(setTestInfo(response.data.test_results));
						} else {
							dispatch(setTestInfo([]));
						}
					})
					.catch(error => {
						console.error("There was an error making the request:", error);
					});
			})
			.catch(error => {
				console.error('Ошибка при выполнении POST запроса', error);
			});
	}

	return (
		<>
			<div className="wrapper-fourth">
				<div className="container">
					<Header></Header>
					<div className="inner-for-type">
						<p className='inner-for-type-p2'>{timeLeft}</p>
						<div className="wrapper-for-type" tabIndex="0" onKeyDown={handleKeyDown} style={{ outline: "none" }}>
							<input type="text" ref={inputRef} onChange={initTyping} className="input-field" />
							<div className="content-box">
								<div className="typing-text" onClick={handleKeyDown}>
									<p onClick={handleKeyDown}>
										{paragraphs.map((char, index) => (
											<span key={index} className={index === charIndex ? 'active' : index < charIndex ? (userInput[index] === char ? 'correct' : 'incorrect') : ''}>
												{char}
											</span>
										))}
									</p>
								</div>
								<div className="content">
									<ul className="result-details">
										<li className="cpm">
											<span>{charIndex - errors}</span>/<span>{paragraphs.length}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{(typingStarted || timeLeft === 0) && <NavLink to="/progress" className='btns btns2 btns6' onClick={handleFinish}>{t('Finish')}</NavLink>}
					</div>
				</div>
			</div>
		</>
	)
}
