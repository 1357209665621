import Header from './Header';
import imgIntr1 from '../assets/intr-img1.svg';
import imgIntr2 from '../assets/intr-img2.svg';
import imgIntr3 from '../assets/intr-img3.svg';
import imgIntr4 from '../assets/intr-img4.svg';
import imgIntr from '../assets/intr-img.png';
import imgIntrNext from '../assets/intr-btn.png';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {useState} from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Introduction() {
	const { t } = useTranslation();
	const testInfo = [
		{
			title: t("KnowledgeTest"),
			info: t("KnowledgeInfo"),
			img: imgIntr1
		},
		{
			title: t("LogicTest"),
			info: t("LogicInfo"),
			img: imgIntr2
		},
		{
			title: t("TypingSpeedTest"),
			info: t("TypingSpeedInfo"),
			img: imgIntr3
		},
		{
			title: t("EnglishTest"),
			info: t("EnglishInfo"),
			img: imgIntr4
		},
	]

	const renderTestInfo = () => {
		return testInfo.map((element, index) => (
			<div className="main-block-two-cards-card" key={index}>
				<img className='main-block-two-cards-card-img' src={element.img} alt="no img" />
				<h3>{element.title}</h3>
				<p>{element.info}</p>
			</div>
    ));
  };

	return (
		<>
			<div className="wrapper-fourth">
				<div className="container">
					<Header></Header>
					<div className="main-block-two">
						<h2 className='main-block-two-head'> <span className='main-block-two-green'>{t('IntroductionTitle1')}</span> {t('IntroductionTitle2')}</h2>
						<div className="main-block-two-cards">
							{renderTestInfo()}
						</div>
						<div className="main-block-two-alert-dop">
							<div className="main-block-two-alert">
								<img src={imgIntr} alt="no img" />
								<p>{t('TestAttempts')}</p>
							</div>
							<NavLink to="/progress">
								<img src={imgIntrNext} alt="no img" />
							</NavLink>
						</div>
						
					</div>
				</div>
			</div>
		</>
	)
}
