import { useEffect, useState } from 'react';
import '../App.css';
import { useParams, NavLink } from "react-router-dom";
import Header from './Header';
import correctImg from "../assets/correct.png";
import incorrectImg from "../assets/incorrect.png";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { setTestInfo } from './modalSlice';


const Question = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const testInfo = useSelector((state) => state.modal.testInfo);
	const [selectedAnswer, setSelectedAnswer] = useState(null);
	const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [correctedAnswers, setCorrectedAnswers] = useState(0);
	const [isCollegeNah, setIsCollegeNah] = useState(false);
	const [questions, setQuestions] = useState([]);
	const { testType } = useParams();

	useEffect(() => {
		const currentTest = {
			testType: testType,
			currentQuestionIndex: 0,
			correctedAnswers: 0
		};
		localStorage.setItem('current-test', JSON.stringify(currentTest));
		loadQuestions(testType);
		axios.get("https://itstep-veral-test.kz/api/v1/checkTokenInfo/", {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				setIsCollegeNah(response.data.token_content.is_college)
			})
			.catch(error => {
				console.error("There was an error making the request:", error);
			});
	}, [testType]);

	const loadQuestions = (testType) => {
		axios.post("https://itstep-veral-test.kz/api/v1/getTest/", {
			type: testType
		})
		.then(response => {
				// console.log(response.data);
				if (response.data.test.length == 30) {
					const arrayCopy = [...response.data.test];
					let tempResult = [];
					for (let i = 0; i < 15; i++) {
						const randomIndex = Math.floor(Math.random() * arrayCopy.length);
						tempResult.push(arrayCopy.splice(randomIndex, 1)[0]);
					}
					setQuestions(tempResult)
				} else {
					setQuestions(response.data.test)
				}
		})
		.catch(error => {
				console.error("There was an error making the request:", error);
		});
	}

	const updateLocalStorage = () => {
		const currentTest = {
			testType: testType,
			currentQuestionIndex: currentQuestionIndex,
			correctedAnswers: correctedAnswers
		};
		localStorage.setItem('current-test', JSON.stringify(currentTest));
	};

	const handleAnswerChange = (answerId) => {
		if (!isAnswerSubmitted) {
			setSelectedAnswer(answerId);
		}
	};

	const handleSubmitAnswer = () => {
		setIsAnswerSubmitted(true);
		const answer = questions[currentQuestionIndex].answers.find(a => a.id === selectedAnswer);
		if (answer && answer.is_correct) {
			setCorrectedAnswers(correctedAnswers + 1);
		}
		updateLocalStorage();
	};

	const handleNextQuestion = () => {
		setSelectedAnswer(null);
		setIsAnswerSubmitted(false);
		setCurrentQuestionIndex(currentQuestionIndex + 1);
		updateLocalStorage();
	};

	const handleFinish = () => {
		const resultPercentage = Math.ceil(correctedAnswers / questions.length * 100);
		axios.post('https://itstep-veral-test.kz/api/v1/updateTestResult/', {
			"link": testType,
			"result": resultPercentage,
		}, {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				// console.log('POST запрос выполнен успешно', response.data);
				axios.get("https://itstep-veral-test.kz/api/v1/getUserResults/", {
					headers: {
						"token": localStorage.getItem("token")
					}
				})
					.then(response => {
						if (response.data.test_results) {
							dispatch(setTestInfo(response.data.test_results));
						} else {
							dispatch(setTestInfo([]));
						}
					})
					.catch(error => {
						console.error("There was an error making the request:", error);
					});
			})
			.catch(error => {
				console.error('Ошибка при выполнении POST запроса', error);
			});
	}


	return (
		<>
			<div className="wrapper-fourth">
				<div className="container">
					<Header />
					{currentQuestionIndex < questions.length ? (
						<div>
							<p className='question-comp'>{t('Question')}: {currentQuestionIndex + 1} {t('OutOf')} {questions.length}</p>
							<p className='question-comp2'>{questions[currentQuestionIndex].text}</p>
							<div className="question-comp-for-questions">
								{questions[currentQuestionIndex].answers.map((answer) => (
									<label key={answer.id} className={`custom-checkbox answer-button ${isAnswerSubmitted && selectedAnswer === answer.id ? (answer.is_correct ? 'green' : 'red') : ''}`}>
										<div className='question-comp-for-inputs'>
											<input
												type="checkbox"
												checked={selectedAnswer === answer.id}
												onChange={() => handleAnswerChange(answer.id)}
												disabled={isAnswerSubmitted}
											/>
											<span className="checkmark"></span>
											{answer.text}
										</div>
										{isAnswerSubmitted && selectedAnswer === answer.id && (answer.is_correct ? <img src={correctImg} alt="Correct" /> : <img src={incorrectImg} alt="Incorrect" />)}
									</label>
								))}
							</div>
							<div className="question-comp-for-btns">
								{!isAnswerSubmitted && (
									<button className='btns btns7' onClick={handleSubmitAnswer} disabled={selectedAnswer === null}>{t('Answer')}</button>
								)}
								{isAnswerSubmitted && currentQuestionIndex < questions.length && (
									<button className='btns btns7' onClick={handleNextQuestion}>{t('Next')}</button>
								)}
							</div>

						</div>
					) : (
						<div className="question-comp-for-final">
							<p className='question-comp2'>{t('CorrectAnswers')} {correctedAnswers} {t('OutOf')} {questions.length} {t('Questions')}</p>
							<NavLink to={isCollegeNah ? "/progress-college" : "/progress"} className='btns btns2 btns6' onClick={handleFinish}>{t('Finish')}</NavLink>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Question;
