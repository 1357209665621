import Header from '../components/Header';
import imgRight from '../assets/registr-img.svg';
import {useState} from 'react';
import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import { Formik, Form, useField } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import imgForBtnReg from '../assets/reg-btn.png';
import imgIntr1 from '../assets/intr-img1.svg';
import imgIntr2 from '../assets/intr-img2.svg';
import imgIntr3 from '../assets/intr-img3.svg';
import imgIntr4 from '../assets/intr-img4.svg';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


export default function Registration() {
	const navigate = useNavigate();
	const { t } = useTranslation();


	const validationSchema = yup.object().shape({
		name: yup.string().required(t('Required')),
		email: yup.string().email(t('InvalidEmail')).required(t('Required')),
		contractNumber: yup.string().required(t('Required')),
		password: yup.string().min(4, t('MinLength')).required(t('Required')),
		passwordTwo: yup.string().oneOf([yup.ref('password'), null], t('PasswordsMustMatch')).min(4, t('MinLength')).required(t('Required')),
	});
	
	const initialValues = {
		name: '',
		email: '',
		contractNumber: '',
		password: '',
		passwordTwo: '',
	};
	
	const onSubmit = (values, { setSubmitting}) => {
		///////
		values.city = JSON.parse(localStorage.getItem("city"))
		localStorage.removeItem("city")
		values.is_college = JSON.parse(localStorage.getItem("is_college"))
		if (JSON.parse(localStorage.getItem("is_college"))) {
			values.results = [
				{
					link: "programmers_college",
					title: "Программирование",
					img: imgIntr2,
					tryCount: 0,
					maxResult: 0,
				},
				{
					link: "designers_college",
					title: "Дизайн",
					img: imgIntr3,
					tryCount: 0,
					maxResult: 0,
				}
			]
		} else {
			values.results = [
				{
					link: "knowledge",
					title: "Знание компьютера",
					img: imgIntr1,
					tryCount: 0,
					maxResult: 0,
				},
				{
					link: "logic",
					title: "Логика",
					img: imgIntr2,
					tryCount: 0,
					maxResult: 0,
				},
				{
					link: "typing",
					title: "Скорость печати",
					img: imgIntr3,
					tryCount: 0,
					maxResult: 0,
				},
				{
					link: "english",
					title: "English",
					img: imgIntr4,
					tryCount: 0,
					maxResult: 0,
				},
			]
		}
		// console.log(values);
		axios.post('https://itstep-veral-test.kz/api/v1/registration/', values)
		.then(response => {
			// console.log('POST запрос выполнен успешно', response.data);
			if (response.data.result == true) {
				localStorage.setItem("token", response.data.jwt_token)
				setSubmitting(false);
				if (JSON.parse(localStorage.getItem("is_college"))) {
					navigate("/introduction-college")
				} else {
					navigate('/introduction')
				}
				localStorage.removeItem("is_college")
			}
		})
		.catch(error => {
			console.error('Ошибка при выполнении POST запроса', error);
		});
	};

	const MyTextInput = ({ label, ...props }) => {
		const [field, meta] = useField(props);
		return (
			<>
				<input className="text-input" {...field} {...props} />
				{meta.touched && meta.error ? (
					<div className="error" style={{ color: '#FFFFFF' }}>{meta.error}</div>
				) : null}
			</>
		);
	};

	return (
		<>
			<div className="wrapper-three">
				<div className="container">
					<Header></Header>
					<div className="main-block">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>
							
							{formik => (
								<>
								<Form className='form-for-reg'>
									<div className="main-block-left2" id='for-mainblockleft2'>
										<div className="form">
											<h2 className="main-block-left2-reg">{t('Registration')}</h2>
								
									
											<label className="lbl-for-reg" htmlFor="inp1">{t('FullName')}</label>
											<MyTextInput
												name="name"
												id="inp1"
												type="text"
											/>
											<label className="lbl-for-reg" htmlFor="inp2">{t('Email')}</label>
											<MyTextInput
												name="email"
												id="inp2"
												type="email"
											/>
											<label className="lbl-for-reg" htmlFor="inp3">{t('ContractNumber')}</label>
											<MyTextInput
												name="contractNumber"
												id="inp3"
												type="number"
											/>
											<label className="lbl-for-reg" htmlFor="inp4">{t('EnterPassword')}</label>
											<MyTextInput
												name="password"
												id="inp4"
												type="password"
											/>
											<label className="lbl-for-reg" htmlFor="inp5">{t('RepeatPassword')}</label>
											<MyTextInput
												name="passwordTwo"
												id="inp5"
												type="password"
											/>
										</div>
									<Button type='submit' className='btn-with-img' id='extForRegistr'><img src={imgForBtnReg} alt="no img" className='img-from-btn' /></Button>
								</div>
							</Form>
						</>)}
						</Formik>
						<div className="main-block-right main-block-right2">
								<NavLink to="/login" className="btns btns3">{t('Login')}</NavLink>
								<img src={imgRight} alt="no img" className='img-for-reg' />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

// const axios = require('axios');

// axios.get('https://example.com/api/data')
//   .then(response => {
//     console.log('GET запрос выполнен успешно', response.data);
//   })
//   .catch(error => {
//     console.error('Ошибка при выполнении GET запроса', error);
//   });

// const postData = {
//   key1: 'value1',
//   key2: 'value2'
// };

// axios.post('https://example.com/api/data', postData)
//   .then(response => {
//     console.log('POST запрос выполнен успешно', response.data);
//   })
//   .catch(error => {
//     console.error('Ошибка при выполнении POST запроса', error);
//   });
