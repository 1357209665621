import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import axios from 'axios';

export default function Admin() {
	const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('All');
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
		axios.get('https://itstep-veral-test.kz/api/v1/getUsers/', {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
		.then(response => {
			// console.log(response.data.users);
			const allUsers = response.data.users || [];
			const allCities = [...new Set(allUsers.map(user => user.city))];
			allUsers.reverse();
			setUsers(allUsers);
			setFilteredUsers(allUsers);
			setCities(allCities);
			// console.log('GET запрос выполнен успешно', response.data);
		})
		.catch(error => {
			console.error('Ошибка при выполнении GET запроса', error);
		});
  }, []);

  const handleCityChange = (event) => {
    const city = event.target.value;
    setSelectedCity(city);
    filterUsers(city, searchName);
  };

  const handleNameChange = (event) => {
    const name = event.target.value;
    setSearchName(name);
    filterUsers(selectedCity, name);
  };

  const filterUsers = (city, name) => {
    let filtered = users;

    if (city !== 'All') {
      filtered = filtered.filter(user => user.city === city);
    }

    if (name) {
      filtered = filtered.filter(user => user.name.toLowerCase().includes(name.toLowerCase()));
    }

    setFilteredUsers(filtered);
  };


	return (
		<>
			<div className="wrapper-finals">
				<div className="container">
					<Header />
					<div className="admin-panel">
						<h2 className='main-block-two-head'>Панель администратора</h2>
						<div className="filter-container">
							<TextField id="outlined-basic" placeholder="Введите имя студента которого хотите найти" variant="outlined" className='admin-panel-input' value={searchName} onChange={handleNameChange} />
						</div>
						<table className="admin-table">
							<thead>
								<tr>
									<th>Email</th>
									<th>Имя</th>
									<th>Номер контракта</th>
									<th><Select
										value={selectedCity}
										onChange={handleCityChange}
										displayEmpty
										className='select-test'
									>
										<MenuItem value="All">Город</MenuItem>
										{cities.map((city, index) => (
											<MenuItem key={index} value={city}>{city}</MenuItem>
										))}
									</Select></th>
									<th>Результаты тестов</th>
								</tr>
							</thead>
							<tbody>
								{filteredUsers.map((user, index) => (
									<tr key={index}>
										<td>{user.email}</td>
										<td>{user.name}</td>
										<td>{user.contractNumber}</td>
										<td>{user.city}</td>
										<td>
											{user.test_results.map((result, idx) => (
												<div key={idx}>
													<strong>{result.title}</strong>: {result.max_result}% (попыток: {result.try_count})
												</div>
											))}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
