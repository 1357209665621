import Header from './Header';
import imgIntr1 from '../assets/intr-img1.svg';
import imgIntr2 from '../assets/intr-img2.svg';
import imgIntr3 from '../assets/intr-img3.svg';
import imgIntr4 from '../assets/intr-img4.svg';
import imgModal from '../assets/img-modal.svg';
import blueStar from '../assets/bluestar.png';
import grayStar from '../assets/graystar.png';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setTestInfo } from './modalSlice';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '57%',
	bgcolor: 'background.paper',
	borderRadius: 20,
	boxShadow: 24,
	padding: "36px",
	paddingTop: "14px",
};

export default function ProgressCollege() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const testInfo = useSelector((state) => state.modal.testInfo);

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const typesTest = ["programmers_college", "designers_college"]

	useEffect(() => {
		axios.get("https://itstep-veral-test.kz/api/v1/getUserResults/", {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				if (response.data.test_results) {
					dispatch(setTestInfo(response.data.test_results));
				} else {
					dispatch(setTestInfo([]));
				}
			})
			.catch(error => {
				console.error("There was an error making the request:", error);
			});
	}, []);


	const renderTestInfo = () => {
		return testInfo.map((element, index) => (
			<NavLink to={element.try_count < 3 ? "/tests/" + typesTest[index] : "/progress-college"} className="main-block-two-cards-card main-block-two-cards-card2" key={index}>
				<img className='main-block-two-cards-card-img' src={element.img} alt="no img" />
				<h3>{element.title}</h3>
				<div className='stars'>
					{element.max_result >= 20 ? <img src={blueStar} alt="no img" /> : <img src={grayStar} alt="no img" />}
					{element.max_result >= 40 ? <img src={blueStar} alt="no img" /> : <img src={grayStar} alt="no img" />}
					{element.max_result >= 60 ? <img src={blueStar} alt="no img" /> : <img src={grayStar} alt="no img" />}
					{element.max_result >= 80 ? <img src={blueStar} alt="no img" /> : <img src={grayStar} alt="no img" />}
					{element.max_result == 100 ? <img src={blueStar} alt="no img" /> : <img src={grayStar} alt="no img" />}
				</div>
				<div className="info-result">
					<p>{element.try_count}/3</p>
					<p>{element.max_result}%</p>
				</div>
			</NavLink>
		));
	};

	return (
		<>
			<div className="wrapper-fourth">
				<div className="container">
					<Header></Header>
					<div className="main-block-two">
						<h2 className='main-block-two-head'> <span className='main-block-two-green'>{t('SelectStart1')}</span> {t('SelectStart3')}</h2>
						<div className="main-block-two-cards main-block-two-cards-college">
							{renderTestInfo()}
						</div>
						<button className='btns btns2' onClick={handleOpen}>{t('SendResults')}</button>
					</div>
				</div>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
			>
				<Box sx={style}>
					<div className="modal2">
						<div className="modal2-left">
							<h2 className='modal2-left-h2'>{t('Congratulations')}</h2>
							<div className='modal2-left-divp'>
								<p className='modal2-left-p1'>{t('WellDone')}</p>
								<p className='modal2-left-p2'>{t('WelcomeToITWorld')}</p>
							</div>
							<button className='btns btns5' onClick={handleClose}>{t('SendResults')}</button>
						</div>
						<div className="modal2-right">
							<img src={imgModal} alt="no img" />
						</div>
					</div>
				</Box>
			</Modal>
		</>
	)
}
