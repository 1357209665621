import Header from './Header';
import typeImg from '../assets/typing.png';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function TypingTest() {
	const { t } = useTranslation();
	useEffect(() => {
		const currentTest = {
			testType: "typing",
			currentQuestionIndex: 0,
			correctedAnswers: 0
		};
		localStorage.setItem('current-test', JSON.stringify(currentTest));
	}, []);
	return (
		<>
			<div className="wrapper-fourth">
				<div className="container">
					<Header></Header>
					<div className="inner-for-type">
						<p className='inner-for-type-p'>{t('TypingTestText1')}<span>60</span>{t('TypingTestText2')}</p>
						<img src={typeImg} alt="no img" />
						<NavLink to="/tests/typing/write" className='btns btns2 btns6'>{t('Start')}</NavLink>
					</div>
				</div>
			</div>
		</>
	)
}
