import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: {
					"Welcome": "Welcome!",
					"AboutAcademy": "About Academy",
					"MyProgress": "My Progress",
					"AdminPanel": "Admin Panel",
					"Main1": "We invite you to enter the world of IT with us!",
					"Main2": "The first thing you need to succeed is to determine",
					"Main3": "starting level. For this we have prepared",
					"Main4": "several tests to pass!",
					"PassTests": "Take the test",
					"SelectCity": "Please select your city",
					"Required": "Required field",
					"InvalidEmail": "Invalid email format",
					"MinLength": "Minimum length is 4 characters",
					"PasswordsMustMatch": "Passwords must match",
					"Registration": "Registration",
					"FullName": "Full Name",
					"Email": "Email",
					"ContractNumber": "Contract Number",
					"EnterPassword": "Enter Password",
					"RepeatPassword": "Repeat Password",
					"Login": "Login",
					"Authorization": "Authorization",
					"InvalidLoginOrPassword": "Invalid login or password",
					"IntroductionTitle1": "The test",
					"IntroductionTitle2": "consists of 4 disciplines",
					"IntroductionTitle3": "consists of 2 disciplines",
					"TestAttempts": "You have 3 attempts for each test",
					"KnowledgeTest": "Computer Knowledge",
					"KnowledgeInfo": "It is needed to find out how well you are with the computer",
					"LogicTest": "Logic",
					"LogicInfo": "Simple logic tasks help determine your creativity",
					"TypingSpeedTest": "Typing Speed",
					"TypingSpeedInfo": "Determines your level of fast typing skills",
					"EnglishTest": "English",
					"EnglishInfo": "The task of the test is to check your knowledge of English",
					"SelectStart1": "Select",
					"SelectStart2": "where you want to start",
					"SelectStart3": "1 direction and take the test",
					"SendResults": "Send results",
					"Congratulations": "Congratulations!",
					"WellDone": "You have successfully completed all the test tasks, and we are happy to welcome you as a student of our academy.",
					"WelcomeToITWorld": "Welcome to the world of IT studies!",
					"TypingTestText1": "Now you will see a text that you need to rewrite in the field below within ",
					"TypingTestText2": " seconds. Typing speed and errors made during the writing will be taken into account.",
					"Start": "Start",
					"Finish": "Finish",
					"Question": "Question",
          "Answer": "Answer",
          "Next": "Next",
          "CorrectAnswers": "You answered correctly to",
          "OutOf": "out of",
          "Questions": "questions.",
					"ProgrammingTest": "Programming",
					"ProgrammingTestInfo": "Determines your level of knowledge in programming",
					"DesignTest": "Design",
					"DesignTestInfo": "Determines your level of knowledge in design",
				}
			},
			ru: {
				translation: {
					"Welcome": "Добро пожаловать!",
					"AboutAcademy": "Об Академии",
					"MyProgress": "Мой прогресс",
					"AdminPanel": "Админская панель",
					"Main1": "Приглашаем войти в мир IT вместе с нами!",
					"Main2": "Первое, что нужно для успеха это определить",
					"Main3": "стартовый уровень. Для этого мы подготовили",
					"Main4": "несколько тестов, которые необходимо пройти!",
					"PassTests": "Пройти тест",
					"SelectCity": "Укажите Ваш город",
					"Required": "Обязательное поле",
					"InvalidEmail": "Неверный формат email",
					"MinLength": "Минимальное количество символов - 4",
					"PasswordsMustMatch": "Пароли должны совпадать",
					"Registration": "Регистрация",
					"FullName": "Ф.И.О",
					"Email": "Email",
					"ContractNumber": "№ Договора",
					"EnterPassword": "Введите пароль",
					"RepeatPassword": "Повторите пароль",
					"Login": "Войти",
					"Authorization": "Авторизация",
					"InvalidLoginOrPassword": "Неправильный логин или пароль",
					"IntroductionTitle1": "Тест",
					"IntroductionTitle2": "состоит из 4 дисциплин",
					"IntroductionTitle3": "состоит из 2 дисциплин",
					"TestAttempts": "На каждый тест Вам дается 3 попытки",
					"KnowledgeTest": "Знание компьютера",
					"KnowledgeInfo": "Нужен для того чтобы узнать, насколько хорошо Вы владеете компьютером",
					"LogicTest": "Логика",
					"LogicInfo": "Простые задачки на логику, помогают определить Вашу креативность",
					"TypingSpeedTest": "Скорость печати",
					"TypingSpeedInfo": "Определяет Ваш уровень умения быстрой печати",
					"EnglishTest": "English",
					"EnglishInfo": "Задача теста проверить Ваше знание английского",
					"SelectStart1": "Выберите",
					"SelectStart2": "с чего хотите начать",
					"SelectStart3": "1 направление и пройдите тест",
					"SendResults": "Отправить результаты",
					"Congratulations": "Поздравляем!",
					"WellDone": "Вы хорошо справились со всеми тестовыми заданиями и мы рады приветствовать Вас в рядах студентов нашей академии.",
					"WelcomeToITWorld": "Добро пожаловать в мир изучения IT!",
					"TypingTestText1": "Сейчас перед Вами появится текст, который Вы должны переписать в поле ниже за ",
					"TypingTestText2": " секунд. Учитывается скорость печати и ошибки, которые Вы можете совершить во время написания текста.",
          "Start": "Начать",
					"Finish": "Завершить",
					"Question": "Вопрос",
          "Answer": "Ответить",
          "Next": "Далее",
          "CorrectAnswers": "Вы ответили правильно на",
          "OutOf": "из",
          "Questions": "вопросов.",
					"ProgrammingTest": "Программирование",
					"ProgrammingTestInfo": "Определяет Ваш уровень знаний в программировании",
					"DesignTest": "Дизайн",
					"DesignTestInfo": "Определяет Ваш уровень знаний в дизайне",
				}
			},
			kz: {
				translation: {
					"Welcome": "Қош келдіңіз!",
					"AboutAcademy": "Академия туралы",
					"MyProgress": "Менің прогресс",
					"AdminPanel": "Әкімшілік панель",
					"Main1": "Сіздерді бізбен бірге IT әлеміне кіруге шақырамыз!",
					"Main2": "Жетістікке жету үшін ең алдымен анықтау керек",
					"Main3": "бастапқы деңгейі. Ол үшін біз дайындадық",
					"Main4": "бірнеше сынақтан өту керек!",
					"PassTests": "Тест тапсырыңыз",
					"SelectCity": "Қалаңызды көрсетіңіз",
					"Required": "Міндетті өріс",
					"InvalidEmail": "Электрондық пошта форматы дұрыс емес",
					"MinLength": "Минималды ұзындық - 4 таңба",
					"PasswordsMustMatch": "Құпия сөздер сәйкес келуі керек",
					"Registration": "Тіркеу",
					"FullName": "Аты-жөні",
					"Email": "Email",
					"ContractNumber": "Шарт нөмірі",
					"EnterPassword": "Құпия сөзді енгізіңіз",
					"RepeatPassword": "Құпия сөзді қайталаңыз",
					"Login": "Кіру",
					"Authorization": "Авторизация",
					"InvalidLoginOrPassword": "Неправильный логин или пароль",
					"IntroductionTitle1": "Тест",
					"IntroductionTitle2": "4 пәннен тұрады",
					"IntroductionTitle3": "2 пәннен тұрады",
					"TestAttempts": "Әрбір тестке 3 рет әрекет жасай аласыз",
					"KnowledgeTest": "Компьютер білімі",
					"KnowledgeInfo": "Бұл сіздің компьютермен қаншалықты жақсы екеніңізді білу үшін қажет",
					"LogicTest": "Логика",
					"LogicInfo": "Қарапайым логикалық тапсырмалар сіздің креативтілігіңізді анықтауға көмектеседі",
					"TypingSpeedTest": "Жазу жылдамдығы",
					"TypingSpeedInfo": "Жазу шеберлігіңіздің деңгейін анықтайды",
					"EnglishTest": "English",
					"EnglishInfo": "Тесттің мақсаты - ағылшын тілін білу деңгейіңізді тексеру",
					"SelectStart1": "Қай жерден",
					"SelectStart2": "бастағыңыз келетінін таңдаңыз",
					"SelectStart3": "1 бағытта және сынақтан өтіңіз",
					"SendResults": "Нәтижелерді жіберу",
					"Congratulations": "Құттықтаймыз!",
					"WellDone": "Сіз барлық тест тапсырмаларын сәтті орындадыңыз және біз сізді академиямыздың студенті ретінде қарсы алуға қуаныштымыз.",
					"WelcomeToITWorld": "IT оқу әлеміне қош келдіңіз!",
					"TypingTestText1": "Енді сізге мәтін шығады, оны төмендегі өріске ",
					"TypingTestText2": " секунд ішінде қайта жазу керек. Жазу жылдамдығы және жазу кезінде жіберілген қателер ескеріледі.",
          "Start": "Бастау",
					"Finish": "Аяқтау",
					"Question": "Сұрақ",
          "Answer": "Жауап беру",
          "Next": "Келесі",
          "CorrectAnswers": "Сіз дұрыс жауап бердіңіз",
          "OutOf": "оның",
          "Questions": "сұрағына.",
					"ProgrammingTest": "Бағдарламалау",
					"ProgrammingTestInfo": "Бағдарламалау бойынша білім деңгейіңізді анықтайды",
					"DesignTest": "Дизайн",
					"DesignTestInfo": "Дизайндағы білім деңгейіңізді анықтайды",
				}
			},
			am: {
				translation: {
					"Welcome": "Բարի գալուստ",
					"AboutAcademy": "Ակադեմիայի մասին",
					"MyProgress": "Իմ առաջընթացը",
					"AdminPanel": "Ադմինիստրատորի վահանակ",
					"Main1": "Հրավիրում ենք ձեզ մուտք գործել ՏՏ աշխարհ մեզ հետ!",
					"Main2": "Առաջին բանը, որ դուք պետք է հաջողության հասնեք, դա որոշելն է",
					"Main3": "մեկնարկային մակարդակ. Դրա համար մենք պատրաստել ենք",
					"Main4": "մի քանի թեստեր անցնելու!",
					"PassTests": "Անցեք թեստը",
					"SelectCity": "Խնդրում ենք նշել ձեր քաղաքը",
					"Required": "Պարտադիր դաշտ",
					"InvalidEmail": "Էլեկտրոնային փոստի անվավեր ֆորմատ",
					"MinLength": "Նվազագույն երկարությունը 4 նիշ է",
					"PasswordsMustMatch": "Գաղտնաբառերը պետք է համապատասխանեն",
					"Registration": "Գրանցում",
					"FullName": "Անուն Ազգանուն",
					"Email": "Email",
					"ContractNumber": "Պայմանագրի համարը",
					"EnterPassword": "Մուտքագրեք գաղտնաբառը",
					"RepeatPassword": "Կրկնեք գաղտնաբառը",
					"Login": "Մուտք գործել",
					"Authorization": "Մուտք",
					"InvalidLoginOrPassword": "Սխալ մուտք կամ գաղտնաբառ",
					"IntroductionTitle1": "Թեստը",
					"IntroductionTitle2": "բաղկացած է 4 առարկաներից",
					"IntroductionTitle3": "բաղկացած է 2 առարկաներից",
					"TestAttempts": "Յուրաքանչյուր թեստի համար ունեք 3 փորձ",
					"KnowledgeTest": "Համակարգչային գիտելիքներ",
					"KnowledgeInfo": "Դա անհրաժեշտ է պարզելու համար, թե որքան լավ եք համակարգչով աշխատում",
					"LogicTest": "Տրամաբանություն",
					"LogicInfo": "Պարզ տրամաբանական խնդիրները օգնում են որոշել ձեր ստեղծագործությունը",
					"TypingSpeedTest": "Արագ գրելու ունակություն",
					"TypingSpeedInfo": "Որոշում է ձեր արագ գրելու հմտությունների մակարդակը",
					"EnglishTest": "Անգլերեն",
					"EnglishInfo": "Թեստի խնդիրը ստուգել ձեր անգլերենի իմացությունը",
					"SelectStart1": "Սկսելու",
					"SelectStart2": "տեղը ընտրեք",
					"SelectStart3": "1 ուղղություն և հանձնեք թեստը",
					"SendResults": "Ուղարկել արդյունքները",
					"Congratulations": "Շնորհավորում ենք!",
					"WellDone": "Դուք հաջողությամբ կատարեցիք բոլոր թեստային առաջադրանքները, և մենք ուրախ ենք ողջունել ձեզ մեր ակադեմիայի ուսանողների շարքում:",
					"WelcomeToITWorld": "Բարի գալուստ IT ուսուցման աշխարհ:",
					"TypingTestText1": "Հիմա դուք կտեսնեք տեքստ, որը պետք է վերաշարադրեք ստորև նշված դաշտում ",
					"TypingTestText2": " վայրկյանների ընթացքում։ Կնկատվի գրելու արագությունը և սխալները, որոնք կարող եք անել գրելու ընթացքում:",
          "Start": "Սկսել",
					"Finish": "Ավարտել",
					"Question": "Հարց",
          "Answer": "Պատասխանել",
          "Next": "Հաջորդ",
          "CorrectAnswers": "Դուք ճիշտ եք պատասխանել",
          "OutOf": "հարցերից",
          "Questions": "հարցերին:",
					"ProgrammingTest": "Ծրագրավորում",
					"ProgrammingTestInfo": "Որոշում է ծրագրավորման ձեր գիտելիքների մակարդակը",
					"DesignTest": "Դիզայն",
					"DesignTestInfo": "Որոշում է դիզայնի ձեր գիտելիքների մակարդակը",
				}
			},
			uz: {
				translation: {
					"Welcome": "Xush kelibsiz!",
					"AboutAcademy": "Akademiya haqida",
					"MyProgress": "Mening taraqqiyotim",
					"AdminPanel": "Administrator paneli",
					"Main1": "Sizni biz bilan IT olamiga kirishga taklif qilamiz!",
					"Main2": "Muvaffaqiyatga erishishingiz kerak bo'lgan birinchi narsa - bu aniqlash",
					"Main3": "boshlang'ich darajasi. Buning uchun biz tayyorladik",
					"Main4": "bir nechta testlardan o'tish kerak!",
					"PassTests": "Sinovdan o'ting",
					"SelectCity": "Iltimos, shaharingizni ko'rsating",
					"Required": "Majburiy maydon",
					"InvalidEmail": "Elektron pochta formati noto'g'ri",
					"MinLength": "Minimal uzunlik 4 ta belgi",
					"PasswordsMustMatch": "Parollar bir xil bo'lishi kerak",
					"Registration": "Ro'yxatdan o'tish",
					"FullName": "To'liq ismi",
					"Email": "Email",
					"ContractNumber": "Shartnoma raqami",
					"EnterPassword": "Parolni kiriting",
					"RepeatPassword": "Parolni takrorlang",
					"Login": "Kirish",
					"Authorization": "Авторизация",
					"InvalidLoginOrPassword": "Xato kirish yoki parol",
					"IntroductionTitle1": "Test",
					"IntroductionTitle2": "4 fanlardan iborat",
					"IntroductionTitle3": "2 fanlardan iborat",
					"TestAttempts": "Har bir test uchun sizda 3 ta urinish bor",
					"KnowledgeTest": "Kompyuter bilimlari",
					"KnowledgeInfo": "Bu sizning kompyuter bilan qanchalik yaxshi ekanligingizni bilish uchun kerak",
					"LogicTest": "Mantiq",
					"LogicInfo": "Oddiy mantiqiy vazifalar sizning ijodingizni aniqlashga yordam beradi",
					"TypingSpeedTest": "Terish tezligi",
					"TypingSpeedInfo": "Tez terish ko'nikmalar darajangizni belgilaydi",
					"EnglishTest": "Ingliz tili",
					"EnglishInfo": "Testning vazifasi sizning ingliz tilini bilishingizni tekshirish",
					"SelectStart1": "Qayerdan",
					"SelectStart2": "boshlashni xohlayotganingizni tanlang",
					"SelectStart3": "1 yo'nalish va testdan o'ting",
					"SendResults": "Natijalarni yuborish",
					"Congratulations": "Tabriklaymiz!",
					"WellDone": "Siz barcha test vazifalarini muvaffaqiyatli bajardingiz va biz sizni akademiyamiz talabasi sifatida kutib olishdan mamnunmiz.",
					"WelcomeToITWorld": "IT o'rganish dunyosiga xush kelibsiz!",
					"TypingTestText1": "Endi sizga matn chiqadi, uni pastdagi maydonga ",
					"TypingTestText2": " soniya ichida qayta yozish kerak bo'ladi. Yozish tezligi va yozish paytida qilgan xatolar hisobga olinadi.",
          "Start": "Boshlash",
					"Finish": "Tugatish",
					"Question": "Savol",
          "Answer": "Javob berish",
          "Next": "Keyingi",
          "CorrectAnswers": "Siz to'g'ri javob berdingiz",
          "OutOf": "savollardan",
          "Questions": "savolga.",
					"ProgrammingTest": "Dasturlash",
					"ProgrammingTestInfo": "Dasturlash bo'yicha bilim darajangizni aniqlaydi",
					"DesignTest": "Dizayn",
					"DesignTestInfo": "Dizayndagi bilim darajangizni aniqlaydi",
				}
			},
		},
		fallbackLng: 'ru',
		detection: {
			order: ['localStorage', 'cookie', 'navigator'],
			caches: ['localStorage', 'cookie']
		},
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
