import { useRouteError } from "react-router-dom";
import imgRight from '../assets/404img.svg';
import Header from '../components/Header';
import { NavLink } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error.statusText || error.message);
	return (
		<>
			<div className="wrapper-two">
				<div className="container">
					<Header></Header>
					<div className="main-block" id="main-block-error">
						<div className="main-block-left">
							<h2>Ошибка 404</h2>
							<p id="main-block-error-p">Упс! Извините, страница не найдена. <br />
								Она была удалена либо вовсе не существовала <br />
								на нашем сайте. </p>
							<NavLink to="/">
								<button className='btns'>На главную</button>
							</NavLink>
						</div>
						<div className="main-block-right">
								<img src={imgRight} alt="no img" className="img-for-stupid-designer" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}