import Header from '../components/Header';
import imgRight from '../assets/start-page-img.svg';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {useState, useEffect} from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import imgIntr1 from '../assets/intr-img1.svg';
import imgIntr4 from '../assets/intr-img4.svg';

export default function Choose() {
	const navigate = useNavigate();

	useEffect(() => {
		axios.get("https://itstep-veral-test.kz/api/v1/checkTokenInfo/", {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				if (response.data.token_content.is_college) {
					navigate("/progress-college")
				} else if (response.data.is_valid) {
					navigate('/progress')
				}
			})
			.catch(error => {
				console.error("There was an error making the request:", error);
				navigate("/")
			});
	}, []);

	const handleClick = (coll) => {
		localStorage.setItem("is_college", coll)
		navigate('/main')
	};

	return (
		<>
			<div className="wrapper">
				<div className="container">
					<div className="cont-for-update">
						<div className="update-for-college main-block-two-cards-card" onClick={() => handleClick(true)}>
							<img className='main-block-two-cards-card-img' src={imgIntr4} alt="no img" />
							<h2>Колледж</h2>
						</div>
						<div className="update-for-college main-block-two-cards-card" onClick={() => handleClick(false)}>
							<img className='main-block-two-cards-card-img' src={imgIntr1} alt="no img" />
							<h2>Академия</h2>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
