import Header from '../components/Header';
import imgCont from '../assets/contacts-info.png';


export default function Contacts() {
	return (
		<>
			<div className="wrapper-fifth">
				<div className="container">
					<Header></Header>
					<div className="main-block-three">
						<h2>Контакты</h2>
						<div className='disp-row'>
							<img src={imgCont} alt="no img" />
							<p>пр. Бухар Жырау 75/3, БЦ Point, 4 этаж</p>
						</div>
						<div className="disp-row2">
							<div className='disp-row2-divs'>
								<p>Приемная комиссия</p>
								<p>ПН-СБ 9:30 - 19:00</p>
								<p>ВС 10:00 - 18:00</p>
								<p>+7 (777) 555-59-95</p>
								<p>karaganda@itstep.org</p>
							</div>
							<div className='disp-row2-divs'>
								<p>HR отдел</p>
								<p>ПН-ПТ 10:00 - 19:00</p>
								<p>+7 (777) 572-03-77</p>
								<p>nizamova_s@itstep.org</p>
							</div>
							<div className='disp-row2-divs'>
								<p>Учебная часть</p>
								<p>ПН-ВС 09:00-18:00</p>
								<p>+7 (777) 55-55-605</p>
							</div>
							<div className='disp-row2-divs'>
								<p>Маркетинговый отдел</p>
								<p>kashapova_ra@itstep.org</p>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
