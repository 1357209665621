import React from 'react';
import './App.css';
import Question from './components/Question';
import Choose from './components/Choose';
import Main from './pages/Main';
import ErrorPage from './pages/error-page';

// https://itstep-veral-test.kz

function App() {
  return (
    <div className="App">
			{/* <Main></Main> */}
			<Choose></Choose>
			{/* <ErrorPage></ErrorPage> */}
			{/* <Question></Question> */}
    </div>
  );
}

export default App;
