import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    value: "",
		testInfo: []
  },
  reducers: {
    setTestInfo: (state, action) => {
      state.testInfo = action.payload
			console.log(state.testInfo);
    },
  },
})

export const { setTestInfo } = modalSlice.actions

export default modalSlice.reducer