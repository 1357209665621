import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.svg'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NavLink } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setTestInfo } from './modalSlice';

export default function Header() {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const testInfo = useSelector((state) => state.modal.testInfo);
	const [lang, setLang] = useState(i18n.language || 'ru');
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const handleChange = (event) => {
		const newLang = event.target.value;
		setLang(newLang);
		i18n.changeLanguage(newLang);
	};
	const handleLogout = () => {
		localStorage.removeItem('token');
		setIsLoggedIn(false);
		setIsAdmin(false);
		navigate('/')
	};
	useEffect(() => {
		axios.get("https://itstep-veral-test.kz/api/v1/checkAdmin/", {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				setIsAdmin(response.data.is_admin);
				axios.get("https://itstep-veral-test.kz/api/v1/checkTokenInfo/", {
					headers: {
						"token": localStorage.getItem("token")
					}
				})
					.then(response => {
						setIsLoggedIn(response.data.is_valid);
					})
					.catch(error => {
						console.error("There was an error making the request:", error);
					});
			})
			.catch(error => {
				console.error("There was an error making the request:", error);
			});

	}, []);
	const handleProgressClick = (e) => {
		if (!isLoggedIn) {
			e.preventDefault();
		}
		axios.get("https://itstep-veral-test.kz/api/v1/checkTokenInfo/", {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				if (response.data.token_content.is_college) {
					navigate("/progress-college")
				} else {
					navigate(isLoggedIn ? "/progress" : "/main");
				}
			})
			.catch(error => {
				console.error("There was an error making the request:", error);
			});
	};

	const handleLogoClick = () => {
		const currentTest = JSON.parse(localStorage.getItem('current-test'));
		let tempNameTest = currentTest.testType == "knowledge" ? "Знание компьютера" : currentTest.testType == "logic" ? "Логика" : currentTest.testType == "english" ? "English" : currentTest.testType == "designers_college" ? "Дизайн" : currentTest.testType == "programmers_college" ? "Программирование" : "Скорость печати"
		let tempRes = 1000
		if (currentTest) {
			// console.log(currentTest.testType);
			axios.get("https://itstep-veral-test.kz/api/v1/getUserResults/", {
				headers: {
					"token": localStorage.getItem("token")
				}
			})
				.then(response => {
					// console.log(tempNameTest);
					// console.log(response.data.test_results);
					response.data.test_results.forEach((el) => {
						if (el.title == tempNameTest) {
							tempRes = el.max_result
							axios.post('https://itstep-veral-test.kz/api/v1/updateTestResult/', {
								"link": currentTest.testType,
								"result": tempRes,
							}, {
								headers: {
									"token": localStorage.getItem("token")
								}
							})
								.then(response => {
									// console.log('POST запрос выполнен успешно', response.data);
									axios.get("https://itstep-veral-test.kz/api/v1/getUserResults/", {
										headers: {
											"token": localStorage.getItem("token")
										}
									})
										.then(response => {
											if (response.data.test_results) {
												dispatch(setTestInfo(response.data.test_results));
											} else {
												dispatch(setTestInfo([]));
											}
										})
										.catch(error => {
											console.error("There was an error making the request:", error);
										});
								})
								.catch(error => {
									console.error('Ошибка при выполнении POST запроса', error);
								});
						}
					})
				})
				.catch(error => {
					console.error("There was an error making the request:", error);
				});
		}
		axios.get("https://itstep-veral-test.kz/api/v1/checkTokenInfo/", {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				if (response.data.token_content.is_college) {
					navigate("/progress-college")
				} else {
					navigate(isLoggedIn ? "/progress" : "/main");
				}
			})
			.catch(error => {
				console.error("There was an error making the request:", error);
				navigate("/")
			});
		
	};

	return (
		<div className="header">
			{/* <NavLink to={isLoggedIn ? "/progress" : "/"}>
				<img src={logo} alt="img" />
			</NavLink> */}
			<img src={logo} alt="logo" className='logoProgHov' onClick={handleLogoClick} />
			<ul className='header-links'>
				<li><a href="https://stepacademy.kz" target='_blank' className='links'>{t('AboutAcademy')}</a></li>
				{/* <li><NavLink to="/contacts" className='links'>Контакты</NavLink></li> */}
				<li><NavLink className={`links ${!isLoggedIn ? 'disabled' : ''}`} onClick={handleProgressClick}>{t('MyProgress')}</NavLink></li>
				{/* <li><a href="https://karaganda.itstep.org/IT_courses_for_adults" target='_blank' className='links'>О курсах</a></li> */}
				{isAdmin && (
					<li><NavLink to="/admin" className='links'>{t('AdminPanel')}</NavLink></li>
				)}
			</ul>
			<div className='header'>
				<Select
					value={lang}
					onChange={handleChange}
					displayEmpty
					className='select-test'
				>
					<MenuItem value="ru">RU</MenuItem>
					<MenuItem value="kz">KZ</MenuItem>
					<MenuItem value="en">EN</MenuItem>
					<MenuItem value="am">AM</MenuItem>
					<MenuItem value="uz">UZ</MenuItem>
				</Select>
				{isLoggedIn && <LogoutIcon className='logout-btn' onClick={handleLogout} />}
			</div>
		</div>
	)
}
