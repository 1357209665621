import Header from '../components/Header';
import imgRight from '../assets/start-page-img.svg';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {useState, useEffect} from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
	borderRadius: 10,
  boxShadow: 24,
	padding: "36px",
  paddingTop: "14px",
	textAlign: "center"
};

export default function Main() {
	const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		axios.get("https://itstep-veral-test.kz/api/v1/checkTokenInfo/", {
			headers: {
				"token": localStorage.getItem("token")
			}
		})
			.then(response => {
				if (response.data.token_content.is_college) {
					navigate("/progress-college")
				} else if (response.data.is_valid) {
					navigate('/progress')
				}
			})
			.catch(error => {
				console.error("There was an error making the request:", error);
			});
	}, []);

	const cities = ['Петропавловск', 'Темиртау', 'Усть-Каменогорск', 'Караганда', 'Астана', 'Актау', 'Шымкент', 'Кокшетау', 'Алматы', 'Уральск', 'Семей', 'Актобе', 'Костанай', 'Атырау', 'Облачный Казахстан'];

	const chunkArray = (arr, chunkSize) => {
    const chunks = []
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize))
    }
    return chunks
  };

	const saveSity = (city) => {
    console.log(city);
		localStorage.setItem("city", JSON.stringify(city))
  };

	const cityGroups = chunkArray(cities, 3)

	const renderCityGroups = () => {
    return cityGroups.map((group, index) => (
      <div className="modal-ext" key={index}>
        {group.map((city, cityIndex) => (
          <NavLink to="/registration" onClick={() => saveSity(city)} key={cityIndex} className='modal-ext-cities'>{city}</NavLink>
        ))}
      </div>
    ));
  };

	return (
		<>
			<div className="wrapper">
				<div className="container">
					<Header></Header>
					<div className="main-block">
						<div className="main-block-left">
							<h2>{t('Welcome')}</h2>
							<p>{t('Main1')} <br />
								{t('Main2')} <br />
								{t('Main3')} <br />
								{t('Main4')} </p>
							<button className='btns' onClick={handleOpen}>{t('PassTests')}</button>
						</div>
						<div className="main-block-right">
								<img src={imgRight} alt="no img" />
						</div>
					</div>
				</div>
			</div>
			<Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div className="modal">
						<h2 className='modal-h2'>{t('SelectCity')}</h2>
						<div className='modal-e'>
							{renderCityGroups()}
						</div>
					</div>
        </Box>
      </Modal>
		</>
	)
}
